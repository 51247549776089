import { Interpolation, Theme } from "@emotion/react";
import { Flex } from "@src/components/Boxes";
import { GImage } from "@src/components/GImage";
import { GQLImage } from "@src/components/GQLImage";
import React, { CSSProperties } from "react";

export type ImageProps = { img: GQLImage } & Omit<React.ComponentProps<typeof GImage>, "img"> &
  Pick<CSSProperties, "width" | "height">;

export const Image = ({ img, alt, css, width, height, ...props }: ImageProps) => {
  img = img ?? { publicURL: "https://placehold.co/600x400" };

  return <GImage img={img} alt={alt} css={[{ width, height }, css]} {...props} />;
};

type FlexBackgroundProps = React.ComponentProps<typeof Flex> & {
  img: GQLImage;
  css?: Interpolation<Theme>;
} & Pick<CSSProperties, "backgroundPosition" | "backgroundSize" | "backgroundColor">;

export const FlexBackground = ({
  css,
  img,
  backgroundColor,
  backgroundPosition,
  backgroundSize,
  ...props
}: FlexBackgroundProps) => {
  img = img ?? { publicURL: "https://placehold.co/600x400" };
  return (
    <Flex
      {...props}
      css={[css, { backgroundColor, backgroundPosition, backgroundSize, backgroundImage: `url(${img?.publicURL})` }]}
    />
  );
};
