import React from "react";
import { FacePaintResponsive } from "@src/components/FacePaintResponsive";
import {
  LabelVariants,
  Typography,
  BodyVariants,
  DisplayVariants,
  HeadlineVariants,
  TypographyVariants,
  TypographyProps,
} from "@src/components/Typography";
import { HasValue } from "./types";

export type TypographyExtended<T> = Omit<TypographyProps, "children" | "variant"> & { variant: T } & HasValue;

export const Label = ({ value, variant, ...props }: TypographyExtended<LabelVariants>) => (
  <Typography variant={variant} {...props}>
    {value}
  </Typography>
);

export const Body = ({ value, variant, ...props }: TypographyExtended<BodyVariants>) => (
  <Typography variant={`Body${variant}`} {...props}>
    {value}
  </Typography>
);

export const Display = ({ value, variant, ...props }: TypographyExtended<DisplayVariants>) => (
  <Typography variant={`Display${variant}`} {...props}>
    {value}
  </Typography>
);

export const Headline = ({ value, variant, ...props }: TypographyExtended<HeadlineVariants>) => (
  <Typography variant={`Headline${variant}`} {...props}>
    {value}
  </Typography>
);

export const TypographyWithValue = ({
  value,
  variant,
  ...props
}: TypographyExtended<FacePaintResponsive<TypographyVariants>>) => (
  <Typography variant={variant} {...props}>
    {value}
  </Typography>
);
