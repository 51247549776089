import React from "react";
import { createContext, ReactNode } from "react";
import { GQLImage } from "../GQLImage";
import { RepeaterItem } from "./transformRepeater";

export type TranslationObject = { [key: string]: string | TranslationObject | RepeaterItem[] };

export type TranslationsValue = string | TranslationObject | TranslationObject[] | RepeaterItem[];

export type TranslationKey = string;

export type Translations = Record<TranslationKey, TranslationsValue>;

export type Images = Record<string | number | symbol, GQLImage>;

export interface PageContextProps {
  translations: Translations;
  images: Images;
}

export const PageContext = createContext<PageContextProps | null>(null);

export interface PageProviderProps<T extends Translations = Translations, I extends Images = Images> {
  translations: T;
  images: I;
  children: ReactNode;
}

export const PageProvider = <T extends Translations, I extends Images>({
  translations,
  images,
  children,
}: PageProviderProps<T, I>) => {
  return <PageContext.Provider value={{ translations, images }}>{children}</PageContext.Provider>;
};
