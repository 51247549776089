export type TranslationReturn = ReturnType<typeof useTranslations>;

import { TranslationContext } from "@src/components/translation/TranslationContext";
// import { faker } from "@faker-js/faker";
import { forEach, isEmpty } from "lodash";
import { useContext } from "react";

// const fakeItUntilYouMakeIt = (obj: Record<string, string>) => {
//   forEach(obj, (v, k) => {
//     if (isEmpty(v)) {
//       obj[k] = k;
//       // if (k.endsWith("title")) {
//       //   obj[k] = faker.lorem.sentence(3);
//       // } else if (k.endsWith("text")) {
//       //   obj[k] = faker.lorem.paragraph(2);
//       // } else if (k.endsWith("button")) {
//       //   obj[k] = faker.lorem.words(2);
//       // }
//     }
//   });
// };

export const useTranslations = () => {
  const t = useContext(TranslationContext);
  const values = {
    apply_top_hero_title: "ClassDojo Mentors",
    apply_top_hero_text: "Join a community of inspiring teachers, dedicated to making every school a happier place. ",
    apply_top_hero_button: "Apply",
    mentor_numbers_hero_a_teacher_title: "A teacher.",
    mentor_numbers_hero_a_leader_title: "A leader.",
    mentor_numbers_hero_a_role_model_title: "A role model.",
    mentor_numbers_hero_a_mentor_title: "A ClassDojo Mentor.",
    mentor_numbers_hero_1_text:
      "Mentors inspire everyday by going above and beyond for their students and their school.",
    mentor_numbers_hero_2_text:
      "They believe that working together with families as teammates helps students succeed—because more engaged means more happy kids.",
    mentor_numbers_hero_3_text:
      "Plus, they are the ClassDojo go-to for new features and resources, helpful content, and staff questions—fostering a vibrant classroom community for all.",
    mentor_numbers_hero_button: "Apply",
    mentor_numbers_1_text: "70k+ Mentors",
    mentor_numbers_2_text: "in 178 countries",
    mentor_numbers_mentors_count_title: "70k+ Mentors",
    mentor_numbers_countries_count_title: "in 178 countries",
    you_might_be_a_mentor_title: "You might be a Mentor if… ",
    // you_might_be_a_mentor_1_text: "",
    mentor_slider_question_1_text: "mentor_slider_question_1_text",
    mentor_slider_slide_1_text: "You love to lead, and love ClassDojo just as much.",
    mentor_slider_slide_2_text: "New resources and tools make you do a happy dance.",
    mentor_slider_slide_3_text:
      "You’re constantly inventing and experimenting to create those “best school ever” vibes",
    mentor_slider_slide_4_text:
      "You’d be excited to host PDs, attend webinars, and connect with other Mentors around the world",
    you_might_be_a_mentor_bottom_question_1_text: "Think that describes you?",
    you_might_be_a_mentor_bottom_question_2_text: "We’d love to have you!",
    mentor_slider_button: "Apply",
    get_the_good_title: "Get the goods",
    get_the_good_text:
      "As a ClassDojo Mentor, you're not just leading the way—you’re gaining access to exclusive perks that empower you to inspire others, grow professionally, and connect with a vibrant community. Here’s what being a mentor unlocks for you:",
    description_panel_1_title: "Networking and community",
    description_panel_1_text:
      "Build your Mentor community with other teachers from around the world, with events, zoom parties, webinars, and more.",
    description_panel_2_title: "Professional development",
    description_panel_2_text:
      "Take advantage of free training, certification opportunities, and exclusive events designed to help you grow.",
    description_panel_3_title: "Exclusive resources and support",
    description_panel_3_text:
      "Gain access to exclusive tools, content, and resources to enhance your classroom and school community",
    description_panel_1_image_text: "You're a teacher",
    description_panel_2_image_text: "You're a innovator",
    description_panel_3_image_text: "You're a cheerleader",
    make_a_difference_title: "Ready to make a difference?",
    make_a_difference_text: "Join the inspiring community and unlock all that being a Mentor has to offer. ",
    make_a_difference_button: "Apply",
    top_nav_text: "Already a Mentor?",
    top_nav_button: "Mentor Access",
    description_panel_1_image_description: "You’re an active educator who uses ClassDojo in the classroom. ",
    description_panel_2_image_description:
      "Mentors are trailblazers—there can only be one per 30 teachers at your school. ",
    description_panel_3_image_description: "You are ready and excited to share your knowledge and joy of learning.  ",
  };

  forEach(values, (_v, k) => {
    values[k] = null;
  });

  forEach(values, (_v, k) => {
    const translation = t.translate(`directus.new_mentor.${k}`).toString();
    if (translation) values[k] = translation;
  });

  return values;
};
