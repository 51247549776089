import React from "react";
import styled from "@emotion/styled";
import { Button } from "@src/components/nessie-web";
import { ButtonProps } from "./utils";
import { HasValue } from "./types";

type SecondaryButtonProps = ButtonProps & HasValue;

export const SecondaryButton = ({ value, ...props }: SecondaryButtonProps) => <Button {...props}>{value}</Button>;

type PrimaryButtonProps = ButtonProps & HasValue;

const ButtonStyled = styled(Button)`
  background-color: ${(props) => props.theme.__new.colors.contentAccent};
  &:hover {
    background-color: ${(props) => props.theme.__new.colors.contentAccentHover};
`;

export const PrimaryButton = ({ value, ...props }: PrimaryButtonProps) => (
  <ButtonStyled {...props}>{value}</ButtonStyled>
);
