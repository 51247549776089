import React from "react";
import { ReactNode } from "react";
import { useImages } from "./useImages";
import { useTranslations } from "./useTranslations";
import { PageProvider } from "../../page-provider/PageProvider";

export const MentorPageProvider = ({ children }: { children: ReactNode }) => {
  const translations = useTranslations();
  const images = useImages();

  return (
    <PageProvider translations={translations} images={images}>
      {children}
    </PageProvider>
  );
};
