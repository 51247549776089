import React, { useEffect, useMemo, useRef, useState, ReactNode } from "react";
import { IconVolumnOn, IconVolumnOff } from "@src/dds-icons";
import isMobile from "@src/utils/isMobile";
import styled from "@emotion/styled";
import Player from "@vimeo/player";

type VimeoEmbedBool = 1 | 0 | "true" | "false";

interface VimeoUrl {
  vimeoId: string;
  autoplay: VimeoEmbedBool;
  autoPause: VimeoEmbedBool;
  muted: VimeoEmbedBool;
  controls: VimeoEmbedBool;
  h?: string;
}

const buildVimeoUrl = ({ vimeoId, autoplay, autoPause, muted, controls, h }: VimeoUrl) => {
  const url = new URL(`https://player.vimeo.com/video/${vimeoId}`);
  Object.entries({
    autoplay,
    autopause: autoPause,
    muted,
    dnt: 1,
    player_id: 0,
    app_id: 58479,
    loop: 1,
    title: 0,
    byline: 0,
    controls,
  }).forEach(([key, value]) => {
    url.searchParams.set(key, value.toString());
  });
  if (h) url.searchParams.set("h", h);
  return url.toString();
};

type VimeoVideoProps = {
  vimeoId: string;
  quote: ReactNode;
  subtitle: any;
  isActive?: boolean;
  h?: string;
};

export default function VimeoVideo({ vimeoId, quote, subtitle, isActive, h }: VimeoVideoProps) {
  const [active, setActive] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Use the isMobile function to determine if the user is on a mobile device
  const mobileInfo = isMobile();
  const isMobileDevice = mobileInfo.any; // Adjust based on your needs

  const srcUrl = useMemo(
    () =>
      buildVimeoUrl({
        vimeoId,
        autoplay: 0, // Do not autoplay; load paused
        autoPause: 0,
        muted: 1, // Keep muted
        controls: 0,
        h,
      }),
    [h, vimeoId],
  );

  // Keep a reference to the player instance
  const playerRef = useRef<Player | null>(null);

  // State to track if the player is ready
  const [playerReady, setPlayerReady] = useState(false);

  useEffect(() => {
    if (iframeRef.current && !playerRef.current) {
      const player = new Player(iframeRef.current);
      playerRef.current = player;

      // Wait for the player to be ready
      player.on("loaded", () => {
        setPlayerReady(true);
      });
    }
  }, []);

  const activateVideo = () => {
    if (!playerReady || !playerRef.current) return;
    setActive(true);
    playerRef.current.play().catch((error) => {
      console.error("Error playing the video:", error);
    });
  };

  const deactivateVideo = () => {
    if (!playerReady || !playerRef.current) return;
    setActive(false);
    playerRef.current.pause().catch((error) => {
      console.error("Error pausing the video:", error);
    });
  };

  useEffect(() => {
    if (isActive !== false) return;
    setActive(false);
    deactivateVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <Wrapper
      active={active}
      onMouseEnter={isMobileDevice ? undefined : activateVideo}
      onMouseLeave={isMobileDevice ? undefined : deactivateVideo}
    >
      <StyledIframe
        ref={iframeRef}
        src={srcUrl}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        //NOTE: title is a property of frame for accessibility and expects a string quote is a ReactNode
        // title={quote}
        title="video"
        allowFullScreen
      ></StyledIframe>
      <Overlay />
      <QuoteText>
        {quote}
        <span>{subtitle}</span>
      </QuoteText>

      <TapToPlayText>{active ? "Pause" : "Play"}</TapToPlayText>
      <IconWrapper active={active}>{active ? <IconVolumnOn size="s" /> : <IconVolumnOff size="s" />}</IconWrapper>
      <FullSizeButton type="button" onClick={isMobileDevice ? (active ? deactivateVideo : activateVideo) : undefined} />
    </Wrapper>
  );
}

// Styled Components (same as before, with minor adjustments)

const QuoteText = styled.div`
  position: absolute;
  top: 36px;
  left: 36px;
  right: 36px;
  font: 500 15px / 20px "Dojo Text", sans-serif;
  margin: 0;
  line-height: 1.2rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
`;

const TapToPlayText = styled.h1`
  display: none;
  opacity: 0.65;
  transition: opacity 250ms ease-out;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 36px;
  font: 500 15px / 20px "Dojo Text", sans-serif;
  margin: 0;
  line-height: 1.2rem;
  color: rgb(255, 255, 255);
  font-weight: 600;
`;

const IconWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  color: rgb(255, 255, 255);
  right: 36px;
  bottom: 36px;
  opacity: ${(props) => (props.active ? "1" : "0.65")};
`;

const Wrapper = styled.div<{ active: boolean }>`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  transition: transform 150ms ease-out;
  width: 100%;
  height: 100%;
  padding-top: 133%;
  ${QuoteText} span {
    opacity: 0;
    margin-top: 8px;
    display: block;
  }
  &:hover ${QuoteText} span {
    transition: opacity 250ms ease-out;
    opacity: 1;
  }

  @media screen and (max-width: 1048px) {
    &:hover {
      transform: none;
    }

    ${QuoteText} span {
      opacity: 1;
    }
    ${TapToPlayText} {
      display: block;
    }

    &:hover ${IconWrapper} {
      opacity: ${(props) => (props.active ? "1" : "0.5")};
    }
  }
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(0deg, rgba(26, 25, 45, 0) 0%, rgba(26, 25, 45, 0.5) 100%);
  border-radius: 24px;
  pointer-events: none;
`;

const FullSizeButton = styled.button`
  position: absolute;
  inset: 0;
  border: none;
  background: transparent;
  // cursor: pointer;
`;
